import React from 'react';

export class EmailForm extends React.Component {
	componentWillUnmount() {
		let id = 'typef_orm',
			formWidget = 'typeform-widget';

		document.querySelectorAll(`#${id}`).forEach((e: any) => e.remove());
		document.querySelectorAll(`.${formWidget}`).forEach((e: any) => e.remove());
	}

	render() {
		let qs,
			js: any,
			q: any,
			s,
			d = document,
			gi = d.getElementById,
			ce = d.createElement,
			gt = d.getElementsByTagName,
			gcn = d.getElementsByClassName,
			id = 'typef_orm',
			formWidget = 'typeform-widget',
			b = 'https://embed.typeform.com/';

		d.querySelectorAll(`#${id}`).forEach((e) => e.remove());
		d.querySelectorAll(`.${formWidget}`).forEach((e) => e.remove());

		if (!gi.call(d, id)) {
			js = ce.call(d, 'script');
			js.id = id;
			js.src = b + 'embed.js';
			q = gt.call(d, 'script')[0];
			q.parentNode.insertBefore(js, q);
		}

		return (
			<>
				<div
					className={formWidget}
					data-hide-headers="true"
					data-hide-footer="true"
					data-url="https://form.typeform.com/to/IGjhMTRW?typeform-medium=embed-snippet"
					style={{ width: '100%', height: '500px' }}
				></div>
			</>
		);
	}
}
