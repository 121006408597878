import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useHistory } from 'react-router-dom';
import './App.css';
import { Button, Form, Icon, Segment } from 'semantic-ui-react';
import {
	ContentTemplateService,
	IFavorite,
	ITemplateParameter,
	TemplatePurpose
} from './services/content-template-service';
import moment from 'moment';
import { EmailForm } from './components/emailForm';

function App() {
	const hostName = window.location.hostname;
	const femaleGender = hostName.toLowerCase().indexOf('bloqueada') >= 0;
	return (
		<div className="App">
			<Router>
				<Switch>
					<Route path="/generar/:target/:goal" render={({ match }) => <Generate match={match} />} />
					<Route path="/ideas/:target/:goal" render={({ match }) => <Ideas match={match} />} />
					<Route path="/favoritos">
						<SavedCopy />
					</Route>
					<Route path="/instagram">
						<PickPurpose platform="instagram" />
					</Route>
					<Route path="/twitter">
						<PickPurpose platform="twitter" />
					</Route>
					<Route path="/facebook">
						<PickPurpose platform="facebook" />
					</Route>
					<Route path="/linkedin">
						<PickPurpose platform="linkedin" />
					</Route>
					<Route path="/">
						<PickPlatform />
					</Route>
				</Switch>
			</Router>
		</div>
	);
}

function PickPurpose(props: any) {
	return (
		<div className="purpose-picker">
			<div className={'app-header'}>
				<h1>
					Estoy
					<br />
					bloqueado
					{ContentTemplateService.getFavorites().length > 0 && (
						<div className="like-tracker">
							<Button
								as={Link}
								to={'/favoritos'}
								content={`${ContentTemplateService.getFavorites().length}`}
								icon={<Icon name="heart" color="red" />}
								labelPosition="right"
							/>
						</div>
					)}
				</h1>
			</div>
			<div className={'app-subheader'}>
				<div className={'breadcrumb'}>
					<Link to={'/'}>
						<Icon name="arrow left" />
					</Link>
					<div>
						<span className="subheader-description">Ideas para</span>
						<span className="subheader-description-platform">
							<Icon name={props.platform} /> {props.platform}
						</span>
					</div>
				</div>
			</div>
			<div className="purpose-selector">
				<Form>
					<Form.Field className="select-platform">
						<label className="label">Con el objetivo de generar...</label>
						<div className="select-platform-buttons">
							<Button
								as={Link}
								to={`/generar/${props.platform}/visibilidad`}
								icon="eye"
								circular
								size="massive"
								labelPosition="left"
								content="Visibilidad"
							/>
							<Button
								as={Link}
								to={`/generar/${props.platform}/conversacion`}
								icon="chat"
								circular
								size="massive"
								labelPosition="left"
								content="Conversaci&oacute;n"
							/>
							<Button
								as={Link}
								to={`/generar/${props.platform}/clientes`}
								icon="user plus"
								circular
								size="massive"
								labelPosition="left"
								content="Clientes"
							/>
							<Button
								as={Link}
								to={`/generar/${props.platform}/dinero`}
								icon="dollar"
								circular
								size="massive"
								labelPosition="left"
								content="Dinero"
							/>
						</div>
					</Form.Field>
				</Form>
			</div>
		</div>
	);
}

interface IGenerateParams {
	type: string;
}

function Generate(props: any) {
	const [update, setUpdate] = useState(0);
	const history = useHistory();
	let platform: String = '';
	let goal: String = '';
	if (props.match) {
		platform = props.match?.params.target;
		goal = props.match?.params.goal;
	}
	const purpose = ContentTemplateService.getPurposeFromString((useParams() as IGenerateParams).type);
	let userParams = ContentTemplateService.getCachedParameters();
	let requiredParams = ContentTemplateService.getParametersForPurpose(TemplatePurpose.Visibility);
	return (
		<div className="purpose-picker">
			<div className={'app-header'}>
				<h1>
					Estoy
					<br />
					bloqueado
					{ContentTemplateService.getFavorites().length > 0 && (
						<div className="like-tracker">
							<Button
								as={Link}
								to={'/favoritos'}
								content={`${ContentTemplateService.getFavorites().length}`}
								icon={<Icon name="heart" color="red" />}
								labelPosition="right"
							/>
						</div>
					)}
				</h1>
			</div>
			<div className={'app-subheader'}>
				<div className={'breadcrumb'}>
					<Link to={'/'}>
						<Icon name="arrow left" />
					</Link>
					<div className="breadcrumb-description">
						<span className="subheader-description">Ideas para generar</span>
						<span>{goal}</span>
						<span className="subheader-description">en</span>
						<span className="subheader-description-platform">
							<Icon name={platform as any} /> {platform}
						</span>
					</div>
				</div>
			</div>
			<div className="platform-selector">
				<Form>
					{requiredParams.map((p) => (
						<ParamFormField
							key={`parm-field-${p.name}`}
							param={userParams.find((up) => up.name === p.name) ?? p}
							onChange={(prop, value) => {
								const userParam = userParams.find((up) => up.name === p.name);
								if (userParam) {
									userParam.value = value;
								} else {
									userParams.push({
										name: p.name,
										value: value,
										description: p.description,
										title: p.title,
										purposes: p.purposes
									});
								}
								ContentTemplateService.saveParameters(userParams);
								setUpdate(update + 1);
							}}
						/>
					))}
					<Button
						as={Link}
						to={`/ideas/${platform}/${goal}`}
						content="Siguiente"
						icon="arrow right"
						labelPosition="right"
						size="huge"
					/>
				</Form>
			</div>
		</div>
	);
}

interface IParamFormFieldProps {
	param: ITemplateParameter;
	onChange: (prop: string, value: string) => void;
}

function ParamFormField(props: IParamFormFieldProps) {
	return (
		<Form.Field className={'form-field'}>
			<label className={'form-field-label'}>{props.param.title}</label>
			<div className={'form-field-description'}>{props.param.description}</div>
			<Form.Input
				type="text"
				className={'form-field-input-field'}
				value={props.param.value}
				onChange={(event) => props.onChange(props.param.name, event.target.value)}
				placeholder={props.param.examples}
			/>
		</Form.Field>
	);
}

function PickPlatform(props: any) {
	return (
		<div className="platform-picker">
			<div className={'app-header home'}>
				<img src="/hero.jpg" alt="Estoy bloqueado (fondo)" />
				<h1>
					Estoy
					<br />
					bloqueado
					{ContentTemplateService.getFavorites().length > 0 && (
						<div className="like-tracker">
							<Button
								as={Link}
								to={'/favoritos'}
								content={`${ContentTemplateService.getFavorites().length}`}
								icon={<Icon name="heart" color="red" />}
								labelPosition="right"
							/>
						</div>
					)}
				</h1>
			</div>
			<div className="platform-selector">
				<Form>
					<Form.Field className="select-platform">
						<label className="label">Necesito ideas para publicar en...</label>
						<div className="select-platform-buttons">
							<Button
								as={Link}
								to="/instagram"
								labelPosition="left"
								icon="instagram"
								size="massive"
								color="orange"
								content="Instagram"
							/>
							<Button
								as={Link}
								to="/facebook"
								labelPosition="left"
								icon="facebook"
								size="massive"
								color="orange"
								content="Facebook"
							/>
							<Button
								as={Link}
								to="/twitter"
								labelPosition="left"
								icon="twitter"
								size="massive"
								color="orange"
								content="Twitter"
							/>
							<Button
								as={Link}
								to="/linkedin"
								labelPosition="left"
								icon="linkedin"
								size="massive"
								color="orange"
								content="LinkedIn"
							/>
						</div>
					</Form.Field>
				</Form>
			</div>
		</div>
	);
}

function Ideas(props: any) {
	const [update, setUpdate] = useState(0);
	let platform: String = '';
	let goal: String = '';
	if (props.match) {
		platform = props.match?.params.target;
		goal = props.match?.params.goal;
	}
	return (
		<div className="purpose-picker">
			<div className={'app-header'}>
				<h1>
					Estoy
					<br />
					bloqueado
					{ContentTemplateService.getFavorites().length > 0 && (
						<div className="like-tracker">
							<Button
								as={Link}
								to={'/favoritos'}
								content={`${ContentTemplateService.getFavorites().length}`}
								icon={<Icon name="heart" color="red" />}
								labelPosition="right"
							/>
						</div>
					)}
				</h1>
			</div>
			<div className={'app-subheader'}>
				<div className={'breadcrumb'}>
					<Link to={'/'}>
						<Icon name="arrow left" />
					</Link>
					<div className="breadcrumb-description">
						<span className="subheader-description">Ideas para generar</span>
						<span>{goal}</span>
						<span className="subheader-description">en</span>
						<span className="subheader-description-platform">
							<Icon name={platform as any} /> {platform}
						</span>
					</div>
				</div>
			</div>
			<div className="platform-selector">
				<ContentSimulator platform={platform} goal={goal} onUpdate={() => setUpdate(update + 1)} />
			</div>
		</div>
	);
}

function ContentSimulator(props: any) {
	return (
		<div>
			<InstagramSimulator platform={props.platform} goal={props.goal} onUpdate={props.onUpdate} />
		</div>
	);
}

function InstagramSimulator(props: any) {
	const [update, setUpdate] = useState(0);
	const [suggestionCount, setSuggestionCount] = useState(1);
	const [isFavorite, setIsFavorite] = useState(false);
	let platform: string = props.platform;
	let goal: string = props.goal;
	const purpose = ContentTemplateService.getPurposeFromString((useParams() as IGenerateParams).type);
	let userParams = ContentTemplateService.getCachedParameters();
	let requiredParams = ContentTemplateService.getParametersForPurpose(TemplatePurpose.Visibility);
	const copy = ContentTemplateService.getTemplate(userParams, TemplatePurpose.Leads);
	const cleanUpParams = (text: string) => {
		return text.replaceAll(/\$[A-Z]*/gi, '__________');
	};
	const copyWithReplacedStrings = cleanUpParams(ContentTemplateService.getTemplateWithStrings(copy));
	if (suggestionCount < 5) {
		return (
			<div>
				<div className={'visualizer instagram'}>
					<div className={'header'}>
						<div className={'avatar'}></div>
						<div className={'author-details'}>
							<div className={'author-name'}>Pedro Lopez</div>
							<div className={'author-published-date'}>Today</div>
						</div>
					</div>
					<div className={'post-image'}></div>
					<div className={'post-text'}>
						<span className={'post-author'}>plopez</span>
						<span className={'post-generated-text'}>
							<TemplateRenderer copy={copy} />
						</span>
					</div>
				</div>
				<Button
					circular
					icon="heart"
					size="huge"
					color={isFavorite ? 'red' : undefined}
					onClick={() => {
						if (!isFavorite) {
							ContentTemplateService.saveToFavorites(platform, goal, copyWithReplacedStrings);
							setUpdate(update + 1);
							setIsFavorite(true);
							props.onUpdate();
						}
					}}
				/>
				<Button
					content="Siguiente"
					icon="arrow right"
					labelPosition="right"
					size="huge"
					color="orange"
					onClick={() => {
						setSuggestionCount(suggestionCount + 1);
						setUpdate(update + 1);
						setIsFavorite(false);
					}}
				/>
			</div>
		);
	} else {
		return (
			<div>
				<EmailForm />
			</div>
		);
	}
}

function TemplateRenderer(props: any) {
	let history = useHistory();
	const copyTokens = props.copy.trim().split(' ');
	const userTokens = ContentTemplateService.getCachedParameters();
	const getParam = (name: string) => {
		return userTokens.find((ut) => ut.name === name);
	};
	const template = (
		<>
			{copyTokens.map((token: string, index: number) => {
				if (token.match(/\$[A-Z]*/gi)) {
					const mainTokens = token.match(/\$[A-Z]*/gi) as string[];
					const tokenName = mainTokens[0];
					const prePostTokens = token.split(tokenName);
					const paramName = tokenName.split('$')[1];
					const param = getParam(paramName);
					return (
						<span key={`token-${index}`}>
							<>{prePostTokens[0]}</>

							<span
								className="template-token"
								title={param?.title}
								onClick={() => {
									history.push(`/generar/instagram/conversacion`);
								}}
							>
								{param && param?.value && param?.value?.trim().length > 0
									? param?.value?.toLowerCase()
									: ContentTemplateService.getParamaterConfig(paramName ?? '')?.title}
							</span>

							<>{prePostTokens[1]} </>
						</span>
					);
				} else {
					return <span key={`token-${index}`}>{token} </span>;
				}
			})}
		</>
	);
	return template;
}

function SavedCopy(props: any) {
	const favorites = ContentTemplateService.getFavorites();
	return (
		<div className="favorites">
			<div className={'app-header'}>
				<h1>
					<div onClick={() => {}}>
						Estoy
						<br />
						bloqueado
					</div>
					{ContentTemplateService.getFavorites().length > 0 && (
						<div className="like-tracker">
							<Button
								as={Link}
								to={'/favoritos'}
								content={`${ContentTemplateService.getFavorites().length}`}
								icon={<Icon name="heart" color="red" />}
								labelPosition="right"
							/>
						</div>
					)}
				</h1>
			</div>
			<div className={'app-subheader'}>
				<div className={'breadcrumb'}>
					<Link to={'/'}>
						<Icon name="arrow left" />
						<div className="breadcrumb-description">
							<span className="subheader-description">Inicio</span>
						</div>
					</Link>
				</div>
			</div>
			<div className="favorite-list">
				{favorites
					.sort((a: IFavorite, b: IFavorite) => {
						return new Date(b.date).getTime() - new Date(a.date).getTime();
					})
					.map((fav: IFavorite) => (
						<SavedCopyItem key={fav.id} item={fav} />
					))}
			</div>
		</div>
	);
}

function SavedCopyItem(props: any) {
	const fav = props.item as IFavorite;
	return (
		<div className="fav-copy-item">
			<div className="fav-copy-details">
				<div className="fav-category">
					<Icon name={fav.platform as any} />
					<span>{fav.platform}</span>
					<Icon name="chevron right" size="tiny" />
					<span>{fav.goal}</span>
				</div>
				<div>{fav.text}</div>
				<div className="time-ago">{moment(fav.date).fromNow()}</div>
			</div>
			<div className="fav-copy-action">
				<Button
					circular
					icon="copy outline"
					basic
					onClick={() => {
						copyToClipboard(fav.text);
						alert(`"${fav.text}"\ncopied to clipboard.`);
					}}
				/>
			</div>
		</div>
	);
}

function copyToClipboard(text: string) {
	var dummy = document.createElement('textarea');
	document.body.appendChild(dummy);
	dummy.value = text;
	dummy.select();
	document.execCommand('copy');
	document.body.removeChild(dummy);
}

export default App;
