import * as _ from 'lodash';
import { isCompositeComponent } from 'react-dom/test-utils';

export enum TemplatePurpose {
	Visibility,
	Conversation,
	Leads,
	Money
}

export interface ITemplateParameter {
	name: string;
	purposes: TemplatePurpose[];
	title?: string;
	description?: string;
	value?: string;
	examples?: string;
}

export interface ITemplate {
	id: string;
	template: string;
	purposes: TemplatePurpose[];
}

export interface IFavorite {
	id: string;
	platform: string;
	goal: string;
	text: string;
	date: Date;
}

export class ContentTemplateService {
	public static getParametersForPurpose(purpose: TemplatePurpose): ITemplateParameter[] {
		return parameters.filter((p) => p.purposes.indexOf(purpose) >= 0);
	}

	public static getTemplate(params: ITemplateParameter[], purpose: TemplatePurpose): string {
		let template = _.sample(templates.filter((t) => t.purposes.indexOf(purpose) >= 0))?.template;
		return template ?? '';
	}

	public static getTemplateWithStrings(copy: string): string {
		const params = ContentTemplateService.getCachedParameters();
		params.forEach((param) => {
			if (param.value && param.value?.trim().length > 0) {
				copy = copy?.replace(`$${param.name.toUpperCase()}`, param.value?.toLowerCase() ?? '_______');
			}
		});
		return copy ?? '';
	}

	public static getCachedParameters(): ITemplateParameter[] {
		const rawParameters = localStorage.getItem('parameters') ?? '';
		if (rawParameters.trim().length > 0) {
			const paramList: ITemplateParameter[] = JSON.parse(rawParameters) ?? [];
			return paramList.filter((p) => p.value && p.value.trim().length > 0);
		}
		return [];
	}

	public static saveParameters(parameters: ITemplateParameter[]) {
		const existingParams = ContentTemplateService.getCachedParameters();
		localStorage.setItem('parameters', JSON.stringify(parameters));
	}

	public static getParamaterConfig(name: string) {
		console.log(`${name} --> ${parameters.find((p) => p.name === name)?.title}`);
		return parameters.find((p) => p.name === name);
	}

	public static getPurposeFromString(purpose: string): TemplatePurpose {
		switch (purpose) {
			case 'visibilidad':
				return TemplatePurpose.Visibility;
			case 'conversacion':
				return TemplatePurpose.Conversation;
			case 'clientes':
				return TemplatePurpose.Leads;
			case 'dinero':
				return TemplatePurpose.Money;
			default:
				return TemplatePurpose.Visibility;
		}
	}

	public static saveToFavorites(platform: string, goal: string, text: string) {
		const favs: IFavorite[] = ContentTemplateService.getFavorites();
		favs.push({
			id: `${Math.floor(Math.random() * 10000000)}`,
			platform: platform,
			goal: goal,
			date: new Date(),
			text: text
		} as IFavorite);
		localStorage.setItem('favorites', JSON.stringify(favs));
	}

	public static getFavorites(): IFavorite[] {
		const rawFavs = localStorage.getItem('favorites');
		return rawFavs ? JSON.parse(rawFavs) : [];
	}
}

const parameters: ITemplateParameter[] = [
	{
		name: 'NICHE',
		purposes: [
			TemplatePurpose.Visibility,
			TemplatePurpose.Conversation,
			TemplatePurpose.Leads,
			TemplatePurpose.Money
		],
		title: '¿Cual es tu nicho?',
		description: '¿Cual es el segmento de tu negocio/industria?',
		examples: 'Productividad, Publicidad, Moda, ...'
	},
	{
		name: 'OFFER',
		purposes: [
			TemplatePurpose.Visibility,
			TemplatePurpose.Conversation,
			TemplatePurpose.Leads,
			TemplatePurpose.Money
		],
		title: '¿Cual es tu oferta?',
		description: '¿Cual es el servicio/producto que quieres ofrecer?',
		examples: 'Servicios de coaching, Ropa, Libro, ...'
	},
	{
		name: 'IDEALCUSTOMER',
		purposes: [
			TemplatePurpose.Visibility,
			TemplatePurpose.Conversation,
			TemplatePurpose.Leads,
			TemplatePurpose.Money
		],
		title: '¿Quien es tu cliente ideal?',
		description: '¿Como definirias a tu public objetivo?',
		examples: 'Emprendedor, Disenyador, ...'
	},
	{
		name: 'HOOK',
		purposes: [
			TemplatePurpose.Visibility,
			TemplatePurpose.Conversation,
			TemplatePurpose.Leads,
			TemplatePurpose.Money
		],
		title: '¿Cual es tu gancho?',
		description: '¿Que ofreces gratis que pueda enganchar?',
		examples: 'Curso, Diseno, Tickets, ...'
	},
	{
		name: 'PROBLEM',
		purposes: [
			TemplatePurpose.Visibility,
			TemplatePurpose.Conversation,
			TemplatePurpose.Leads,
			TemplatePurpose.Money
		],
		title: '¿Que problema resuelves?',
		description: '¿Que es lo que intentas resolver?',
		examples: 'Estar bloqeuado, Incapcidad de dormir, ...'
	},
	{
		name: 'GOAL',
		purposes: [
			TemplatePurpose.Visibility,
			TemplatePurpose.Conversation,
			TemplatePurpose.Leads,
			TemplatePurpose.Money
		],
		title: '¿Cual es el objetivo?',
		description: '¿Por que alguien deberia interesarle lo que ofreces?',
		examples: 'Dormir mejor, Incrementer productividad, ...'
	}
];

const templates: ITemplate[] = [
	{
		id: '1000001',
		template: '10 ideas para $GOAL mejor.',
		purposes: [
			TemplatePurpose.Visibility,
			TemplatePurpose.Conversation,
			TemplatePurpose.Leads,
			TemplatePurpose.Money
		]
	},
	{
		id: '1000002',
		template: 'Consejos que me madre me dio para $GOAL.',
		purposes: [TemplatePurpose.Visibility]
	},
	{
		id: '1000003',
		template: 'Que no hacer durante la pandemia para conseguir $GOAL.',
		purposes: [TemplatePurpose.Leads, TemplatePurpose.Visibility]
	}
];
